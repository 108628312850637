import "./ColorDetailsPlaceholder.css";


function ColorDetailsPlaceholder() {

  return (
    <p class="colordetailsplaceholder">
      Klikk på fargene ovenfor for å finjustere paletten
    </p>
  );
}

export default ColorDetailsPlaceholder;
