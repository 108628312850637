import "./VariableDetailsPlaceholder.css";


function VariableDetailsPlaceholder() {

  return (
    <p class="variabledetailsplaceholder">
      Klikk på boksene i designet for å endre fargene
    </p>
  );
}

export default VariableDetailsPlaceholder;
